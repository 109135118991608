.App {
  text-align: center;
}
body {
  background-color: #070707;
  min-height: 100vh;
  padding-top: 40px;
}
.App-logo {
  height: 105px;
  pointer-events: none;
  margin: auto;
}
.logo-container {
  justify-content: center;
  display: flex;
}
p {
  color: rgb(227, 227, 227); 
}
.trade-container {
  margin-left: 80px;
  display: flex;
}
.trade-list {
  width: 760px;
}
.head-text {
  font-size: 70px;
  color: #EEEEEE;
  font-weight: bold;
  margin: 10px;
}
.head-container {
  align-items: center;
  text-align: center;
}
h3 {
  color:rgb(236, 236, 236);
  font-size: 25px;
  font-weight: normal;
  margin-top: 5px;
}
h2 {
  color: rgb(236, 236, 236);
  font-size: 50px;
  font-weight: normal;
}
h4 {
  color: rgb(236, 236, 236);
  font-size: 30px;
  font-weight: normal;
}
.learn-more {
  width: 200px;
  height: 60px;
  border: 3px solid yellow;
  margin: auto;
  margin-top: 60px;
}
.learn-text {
  font-size: 20px;
  color: yellow;
  font-weight: bold;
}
li {
  color: rgb(236, 236, 236);
  padding: 15px;
  font-size: 20px;
  font-family: Verdana;
}
.hand {
  width: 500px;
  height: 500px;
  margin: auto;
}
.analysis-container {
  background-color: #2C617D;
  width: 100%;
  height: 700px;
  justify-content: center;
  display: flex;
}
.phone {
  width: 650px;
}
.analysis-text-container {
  background-color: black;
  padding: 80px;
  padding-top: 5px;
  width: 570px;
}
.insight-container {
  justify-content: center;
  text-align: center;
  margin-top: 40px;
}
.insight-image-container {
  display: flex;
  justify-content: center;
}
.insight-image {
  width: 230px;
  padding: 10px;
}
.insight-img-text {
  width: 230px;
  padding: 25px;
}
h5 {
  color: rgb(236, 236, 236);
  font-size: 25px;
  margin-top: 0;
}
.pick-container {
  display: flex;
  justify-content: center;
}
.pick {
  width: 200px;
  height: 60px;
  border: 3px solid yellow;
  margin-top: 50px;
  margin-left: 10px;

}
.candlestick {
  width: 500px;
  padding-right: 60px;
}
.graph {
  width: 500px;
}