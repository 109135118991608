@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@keyframes slideIn {
	from {
		opacity: 0;
		transform: translateX(-50%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
.home-container {
	background-color: #0d233b;
	min-height: 100vh;
	width: 100%;
}

.computer {
	height: 65%;
	margin-right: 140px;
}
.App {
	text-align: center;
}
body {
	background-color: #070707;
	min-height: 100vh;
	padding-top: 60px;
}
.App-logo {
	height: 80px;
	margin: auto;
	margin-left: 10px;
	padding: 10px;
	margin-bottom: 8px;
}
.logo-container {
	justify-content: center;
	display: flex;
}
p {
	font-size: 22px;
	color: #aac9e6;
}
.head-text {
	font-size: 4em;
	width: 500px;
	color: #eef7f7;
	font-weight: normal;
	margin: 10px;
	text-align: left;
	font-family: 'Noto Sans', sans-serif;
}
.subtitle {
	font-size: 2.7em;
	color: #aac9e6;
	margin-left: 10px;
	margin-top: 40px;
	width: 80%;
	animation: slideIn 0.6s ease-out;
}
.head-container {
	align-items: center;
	text-align: center;
	display: flex;
	margin-top: 20px;
}
.header {
	padding: 10px 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 110px;
	top: 0;
	z-index: 1000;
}
.contact-text {
	margin-right: 70%;
	font-size: 1.6em;
}
.contact-text:hover {
	text-decoration: underline;
	cursor: pointer;
}
h2 {
	color: #eef7f7;
	font-size: 50px;
	margin-top: 20px;
	font-weight: normal;
}
.graph {
	/* width: 650px; */
	width: 50%;
	margin-top: 40px;
	margin-left: 20px;
	border-radius: 8px;
}
.learn-more-button {
	width: 180px;
	height: 60px;
	border-radius: 6px;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	background-color: #59c3c6;
	font-size: 20px;
	font-weight: bold;
	margin-left: 10px;
	margin-top: 20px;
}
.learn-more-button:hover {
	cursor: pointer;
}
.head-text-container {
	text-align: left;
	padding-left: 10%;
}
body {
	margin: 0;
	padding: 0;
}
.connect-container {
	text-align: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 40px;
}
.email-container {
	display: flex;
}
.email {
	height: 25px;
	padding-right: 5px;
}
.line {
	width: 350px;
	height: 1.5px;
	background-color: #aac9e6;
	margin: auto;
	border-radius: 5px;
	margin-top: 90px;
}
.discover {
	padding-top: 70px;
	text-align: center;
}
.button-container {
	display: flex;
	justify-content: center;
	gap: 30px;
}
.information-container {
	width: 75vw;
	margin: auto;
	text-align: left;
	margin-top: -50px;
}
.information-text {
	line-height: 1.8;
	margin: auto;
	font-size: 23px;
}
.disclaimer-button:hover {
	text-decoration: none;
}
.email-text {
	margin-top: 0px;
	margin-left: 5px;
}
@media (max-width: 255px) {
	.App-logo {
		height: 35px;
		padding: 3px;
		margin: 0;
	}
	.head-text {
		font-size: 1.6em;
		width: 90%;
	}
	.head-container {
		flex-direction: column;
		gap: 50px;
	}
	.subtitle {
		font-size: 1.2em;
		width: 90%;
		margin-top: 20px;
	}
	.head-text-container {
		margin: auto;
	}
	.computer {
		height: 25vh;
		margin-left: 140px;
		padding-left: 0;
	}
	.graph {
		width: 80vw;
		border-radius: 5px;
	}
	.contact-text {
		margin-right: 0%;
		font-size: 1em;
	}
	.information-text {
		font-size: 1em;
		margin-top: 25px;
	}
	.line {
		width: 150px;
		margin-top: 20px;
	}
	.learn-more-button {
		width: 50%;
		height: 7vh;
		border-radius: 5px;
		font-size: 1em;
	}
	h2 {
		font-size: 1.5em;
	}
	.email-text {
		font-size: 1em;
	}
	.email {
		height: 3vh;
	}
}
@media (min-width: 255px) and (max-width: 374px) {
	.App-logo {
		height: 45px;
		padding: 6px;
		margin: 0;
	}
	.head-text {
		font-size: 2em;
		width: 90%;
	}
	.head-container {
		flex-direction: column;
		gap: 50px;
	}
	.subtitle {
		font-size: 1.6em;
		width: 90%;
	}
	.head-text-container {
		margin: auto;
	}
	.computer {
		height: 20vh;
		margin-left: 60px;
		padding-left: 0;
	}
	.graph {
		width: 80vw;
	}
	.contact-text {
		margin-right: 8%;
		font-size: 1em;
	}
	.information-text {
		font-size: 1em;
		margin-top: 25px;
	}
	.line {
		width: 150px;
		margin-top: 20px;
	}
	.learn-more-button {
		width: 50%;
		height: 8vh;
		border-radius: 7px;
		font-size: 1.2em;
	}
	h2 {
		font-size: 2em;
	}
}
@media (min-width: 375px) and (max-width: 499px) {
	.App-logo {
		height: 60px;
	}
	.head-text {
		font-size: 2.5em;
		width: 90%;
	}
	.head-container {
		flex-direction: column;
		gap: 50px;
	}
	.subtitle {
		font-size: 1.6em;
		width: 90%;
	}
	.head-text-container {
		margin: auto;
	}
	.computer {
		height: 30vh;
		margin-left: 100px;
		padding-left: 0;
	}
	.graph {
		width: 80vw;
	}
	.contact-text {
		margin-right: 6%;
		font-size: 1.4em;
	}
	.information-text {
		font-size: 1.4em;
		margin-top: 30px;
	}
	.line {
		width: 150px;
		margin-top: 20px;
	}
	h2 {
		font-size: 2em;
	}
}

@media (min-width: 500px) and (max-width: 609px) {
	.App-logo {
		height: 70px;
	}
	.head-text {
		font-size: 3em;
		width: 90%;
	}
	.head-container {
		flex-direction: column;
		gap: 50px;
	}
	.subtitle {
		font-size: 2em;
		width: 90%;
	}
	.head-text-container {
		margin: auto;
	}
	.computer {
		height: 20vh;
		margin-left: 40px;
		padding-left: 0;
	}
	.graph {
		width: 80vw;
	}
	.contact-text {
		margin-right: 20%;
		font-size: 1.6em;
	}
	.information-text {
		font-size: 1.4em;
		margin-top: 30px;
	}
	h2 {
		font-size: 2.5em;
	}
}
@media (min-width: 610px) and (max-width: 779px) {
	.head-text {
		font-size: 3em;
		width: 90%;
	}
	.head-container {
		flex-direction: column;
		gap: 50px;
	}
	.subtitle {
		font-size: 2em;
		width: 90%;
	}
	.head-text-container {
		margin: auto;
	}
	.computer {
		height: 25vh;
		margin-left: -30px;
		padding-left: 0;
	}
	.graph {
		width: 80vw;
	}
	.contact-text {
		margin-right: 30%;
		font-size: 1.6em;
	}
	.information-text {
		font-size: 1.6em;
		margin-top: 30px;
	}
}
@media (min-width: 780px) and (max-width: 1049px) {
	.head-text {
		font-size: 3em;
		width: 90%;
	}
	.subtitle {
		font-size: 2em;
		width: 90%;
	}
	.computer {
		height: 34vh;
		margin-right: 10px;
	}
	.contact-text {
		margin-right: 50%;
		font-size: 1.5em;
	}
	.App-logo {
		height: 80px;
		margin-left: 10px;
		padding: 10px;
	}
	.graph {
		width: 70vw;
	}
}
@media (min-width: 1050px) and (max-width: 1303px) {
	.head-text {
		font-size: 3.7em;
		width: 90%;
	}
	.subtitle {
		font-size: 2.3em;
		width: 80%;
	}
	.contact-text {
		margin-right: 45%;
		font-size: 2.3em;
	}
	.learn-more-button {
		width: 35%;
		height: 8vh;
		border-radius: 10px;
		font-size: 1.8em;
	}
	.information-text {
		line-height: 1.8;
		margin: auto;
		margin-top: 40px;
		font-size: 1.8em;
	}
	.line {
		margin-top: 100px;
	}
	.email-text {
		font-size: 2em;
	}
	.email {
		margin-top: 8px;
	}
	.App-logo {
		height: 110px;
		margin-left: 10px;
		padding: 15px;
		margin-bottom: 8px;
	}
	.computer {
		height: 40vh;
	}
}

@media (min-width: 1304px) and (max-width: 1439px) {
	.head-text {
		font-size: 4em;
	}
	.subtitle {
		font-size: 2.7em;
		width: 80%;
	}
	.contact-text {
		margin-right: 50%;
		font-size: 2.5em;
	}
	.learn-more-button {
		width: 35%;
		height: 8vh;
		border-radius: 10px;
		font-size: 2em;
	}
	.information-text {
		line-height: 1.8;
		margin: auto;
		margin-top: 40px;
		font-size: 2em;
	}
	.line {
		margin-top: 100px;
	}
}
@media (min-width: 1440px) and (max-width: 1549px) {
	.head-text {
		font-size: 4em;
		width: 500px;
		margin: 10px;
	}
	.subtitle {
		font-size: 2.7em;
		margin-left: 10px;
		margin-top: 40px;
		width: 80%;
	}
	.App-logo {
		height: 80px;
		margin: auto;
		margin-left: 10px;
		padding: 10px;
		margin-bottom: 8px;
	}
	.contact-text {
		margin-right: 70%;
		font-size: 1.6em;
	}
	.computer {
		height: 65%;
		margin-right: 140px;
	}
	.line {
		width: 350px;
		height: 1.5px;
		border-radius: 5px;
		margin-top: 90px;
		margin-bottom: 50px;
	}
	.information-text {
		line-height: 1.8;
		margin: auto;
		font-size: 23px;
	}
}
@media (min-width: 1760px) and (max-width: 1999px) {
	.head-text {
		font-size: 5em;
		width: 100%;
	}
	.subtitle {
		font-size: 4em;
		width: 100%;
	}
	.computer {
		height: 65vh;
	}
	.learn-more-button {
		width: 35%;
		height: 12vh;
		border-radius: 15px;
		font-size: 2.5em;
	}
	.contact-text {
		margin-right: 60%;
		font-size: 2.8em;
	}
	.information-text {
		font-size: 2.3em;
		margin-top: 40px;
	}
	.line {
		margin-top: 70px;
	}
	.email-text {
		font-size: 2em;
	}
	.email {
		margin-top: 8px;
	}
}
@media (min-width: 2000px) and (max-width: 2804px) {
	.head-text {
		font-size: 7em;
		width: 90%;
	}
	.subtitle {
		font-size: 5em;
		margin-left: 10px;
		margin-top: 40px;
		width: 80%;
	}
	.computer {
		height: 55vh;
		margin-right: 0px;
	}
	.contact-text {
		margin-right: 55%;
		font-size: 3.5em;
	}
	.App-logo {
		height: 13vh;
		padding: 30px;
	}
	.learn-more-button {
		width: 400px;
		height: 150px;
		border-radius: 15px;
		font-size: 3.5em;
	}
	.head-container {
		align-items: center;
		justify-content: center;
		text-align: center;
		display: flex;
		margin-top: 20px;
		gap: 0;
	}
	.header {
		padding: 10px 20px;
		height: 30%;
		top: 0;
	}
	.information-text {
		line-height: 1.8;
		padding: 4%;
		width: 98%;
		margin: auto;
		font-size: 3.3em;
	}
	.head-text-container {
		max-width: 50%;
		margin: 0;
		padding-left: 0;
	}
	.graph {
		width: 70%;
		margin-top: 40px;
		margin-left: 20px;
		border-radius: 1%;
	}
	.line {
		width: 20%;
		height: 0.3vh;
		background-color: #aac9e6;
		margin: auto;
		border-radius: 5px;
		margin-top: 100px;
	}
	h2 {
		font-size: 5.5em;
		margin-top: 20px;
	}
	.connect-container {
		margin-top: 1%;
		padding-bottom: 40px;
	}
	.email-text {
		margin-top: -1%;
		margin-left: 5px;
		font-size: 3.5em;
	}
	.email {
		height: 4vh;
		padding-right: 5px;
	}
}

@media (min-width: 2805px) {
	.head-text {
		font-size: 8em;
		width: 70%;
	}
	.subtitle {
		font-size: 6em;
		margin-left: 10px;
		margin-top: 40px;
		width: 70%;
	}
	.computer {
		height: 70vh;
		margin-right: 0px;
	}
	.contact-text {
		margin-right: 65%;
		font-size: 4em;
	}
	.App-logo {
		height: 13vh;
		padding: 30px;
	}
	.learn-more-button {
		width: 400px;
		height: 150px;
		border-radius: 15px;
		font-size: 3.5em;
	}
	.head-container {
		align-items: center;
		justify-content: center;
		text-align: center;
		display: flex;
		margin-top: 20px;
		gap: 0;
	}
	.header {
		padding: 10px 20px;
		height: 30%;
		top: 0;
	}
	.information-text {
		line-height: 1.8;
		padding: 4%;
		width: 98%;
		margin: auto;
		font-size: 3.3em;
	}
	.head-text-container {
		max-width: 50%;
		margin: 0;
		padding-left: 0;
	}
	.graph {
		width: 70%;
		margin-top: 40px;
		margin-left: 20px;
		border-radius: 1%;
	}
	.line {
		width: 20%;
		height: 0.3vh;
		background-color: #aac9e6;
		margin: auto;
		border-radius: 5px;
		margin-top: 100px;
	}
	h2 {
		font-size: 5.5em;
		margin-top: 20px;
	}
	.connect-container {
		margin-top: 1%;
		padding-bottom: 40px;
	}
	.email-text {
		margin-top: -1%;
		margin-left: 5px;
		font-size: 3.5em;
	}
	.email {
		height: 4vh;
		padding-right: 5px;
	}
}
/* @media (max-width: 375px) {
  .head-text {
    font-size: 2em;
    margin-left: 5%;
    width: 80%;
  }
  .subtitle {
    font-size: 1.5em;
    margin-left: 5%;
    margin-top: 5%;
    width: 80%;
  }
  .learn-more-button {
    width: 40%;
    height: 6vh;
    margin-left: 5%;
    border-radius: 4px;
    font-size: 1.2em;
  }
  .contact-text {
    margin-right: 10%;
    font-size: 1.2em;
  }
  .App-logo {
    height: 8vh;
    padding: 3%;
  }
  h2 {
    font-size: 2em;
    margin-top: 20px;
  }
  .email {
    height: 3vh;
    padding-right: 5px;
    margin-top: 0.6vh;
  } 
  .computer {
    margin: auto;
  }
  .graph {
    width: 80vw;
    border-radius: 5px;
 }
 .information-text {
  line-height: 1.6;
  font-size: 1em;
 }
 
 }
  */
